import React, { useState } from 'react'
import Layout from '../components/Layout/layout'
import Seo from '../components/Seo/seo'
import axios from 'axios'
import LoadingSpinner from '../components/LoadingSpinner/LoadingSpinner'
import styled from 'styled-components'

const Div = styled.div`
    padding:6rem;
    h1 {
        font-family: "Barlow Condensed";
        color: var(--textcolor);
        margin-bottom: 1.5rem;
        font-size: 3.125rem;
        line-height: 1.5;
        font-weight: 700;

        @media (min-width: 768px) {
            font-size: 3.125rem;
        }
    }
    p{
        color: var(--textcolor);
        font-family: "Manrope", sans-serif;
        margin-bottom: 1.5rem;
        font-size: 1.125rem;
        line-height: 1.5;
    }
    form.contact-form{
        width:100%;
    @media (min-width: 991px) {
        width: 65%;
        }
    input{
        color: var(--textcolor);
        font-family: "Manrope", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
    }
    label{
        color: var(--textcolor);
        font-family: "Manrope", sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        margin-bottom: 23px;
    }
    .error{
        width: 100%;
        margin-top: 0.25rem;
        font-size: 0.875em;
        color: #dc3545;
        margin:0;
    }
    textarea {
       color: var(--textcolor);
        font-family: "Manrope", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
    }

    }
    div.info-contact{
        margin: 35px 0;
        font-family: "Barlow Condensed";
    }
    button.btn-submit{
        font-size: 1rem;
        width: 25%;
        border: none;
        background-color: rgb(130, 36, 227);
        color: rgb(255, 255, 255);
        border-radius: 5px;
        padding: 0.375rem 0.75rem;
        &:hover{
            background-color:black !important;
        }
    }
    
`
const Contact = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [msg, setMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isRequiredMsg, setisRequiredMsg] = useState('')
    const [emailError, setEmailError] = useState('')
    const [phnNum, setPhnNum] = useState('')

    const hideResponse = () => {
        if (document !== undefined) {
            (document.getElementsByClassName('resp-msg') as HTMLCollectionOf<HTMLElement>)[0].style.display = "none";
        }
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (name === '' || phone === '' || email === '' || msg === '') {
            setisRequiredMsg('Please fill all the fields!!!')
        }
        else if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
            setPhnNum('')
            setEmailError('Please provide valid email!!!')
        }
        else if (!phone.match(/^\d{10}$/)) {
            setEmailError('')
            setPhnNum('Please fill the correct phone number!!!')
        }
        else {
            setPhnNum('')
            setEmailError('')
            setIsLoading(true);
            await axios
                .post("https://strapi.phonera.in/api/contact-forms", {
                    data: {
                        name: name,
                        email: email,
                        phone: phone,
                        msg: msg
                    },
                })
                .then(function (response) {
                    setIsLoading(false);
                    setisRequiredMsg('')
                    setName('');
                    setEmail('');
                    setPhone('');
                    setMsg('');
                    (document.getElementsByClassName('resp-msg') as HTMLCollectionOf<HTMLElement>)[0].style.display = "block";
                    setTimeout(hideResponse, 5000);
                })
                .catch(function (error) {
                    setIsLoading(false);
                    console.error(error);
                });
        }
    }
    return (
        <Layout>
            <Seo title={'Contact US'}
                description={'Phonera Contact Page'}
            />
            <Div className="py-5">
                <div className="container">
                    <h1 >Contant Us</h1>
                    <form className='contact-form'>
                        <div className="mb-3 row align-items-baseline">
                            <label htmlFor="name" className="col-sm-2 col-form-label">Name</label>
                            <div className="col-sm-10">
                                <input type="text" className="form-control" id="name" placeholder="Enter Your Name" value={name} onChange={(e: any) => setName(e.target.value)} />
                                {name == '' ? <p className='error'>{isRequiredMsg}</p> : null}
                            </div>
                        </div>
                        <div className="mb-3 row align-items-baseline">
                            <label htmlFor="email" className="col-sm-2 col-form-label">Email</label>
                            <div className="col-sm-10">
                                <input type="email" className="form-control" id="email" placeholder="Enter Your Email" value={email} onChange={(e: any) => setEmail(e.target.value)} />
                                {email == '' ? <p className='error'>{isRequiredMsg}</p> : null}
                                <p className='error'>{emailError}</p>
                            </div>
                        </div>
                        <div className="mb-3 row align-items-baseline">
                            <label htmlFor="phone" className="col-sm-2 col-form-label">Phone Number</label>
                            <div className="col-sm-10">
                                <input type="text" className="form-control" id="phone" placeholder="Enter Your Phone Number" value={phone} onChange={(e: any) => setPhone(e.target.value)} />
                                {phone == '' ? <p className='error'>{isRequiredMsg}</p> : null}
                                <p className='error'>{phnNum}</p>
                            </div>
                        </div>
                        <div className="mb-3 ">
                            <label htmlFor="msg" className="form-label">Your message</label>
                            <textarea className="form-control" rows={3} placeholder="Leave your message here" id="msg" value={msg} onChange={(e: any) => setMsg(e.target.value)}></textarea>
                            {msg == '' ? <p className='error'>{isRequiredMsg}</p> : null}
                        </div>
                        <h6 className="resp-msg">Your message has been Sent!!!</h6>
                        {isLoading ? <LoadingSpinner /> : null}
                        <button style={{
                            fontSize: '1rem',
                            width: '25%'
                        }} type="button" className="btn-submit" onClick={handleSubmit}>SUBMIT</button>
                    </form>
                    <div className='info-contact'>
                        <p>Our support team is available 24 hours a day, 7 days a week, except on Saturday and Sunday, which are weekends. But we will try our best to reach you within the next two consecutive days of your inquiry.</p>
                        <p>You can also check us on our various social media platforms; the icons are available on the page htmlFor a quick glance into some interesting interviews, lots of gossip, new pictures, and live stories.</p>
                        <p>Stay Updated</p>
                        <p>Stay <strong>PHONERA</strong>.</p>
                    </div>
                </div>
            </Div>
        </Layout>
    )
}

export default Contact